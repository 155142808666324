import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../style/VideoPlayer.scss';
import Youtube from '../images/Admin/Youtube.png'
const VideoPlayer = ({ playlistId, apiKey, title, endImage, endLink }) => {
  const [videos, setVideos] = useState([]);
  const [error, setError] = useState(null);
  const [nextPageToken, setNextPageToken] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [videosPerPage] = useState(5);

  useEffect(() => {
    const fetchVideos = async () => {
      try {
        const response = await axios.get('https://www.googleapis.com/youtube/v3/playlistItems', {
          params: {
            part: 'snippet',
            maxResults: 50,
            playlistId,
            key: apiKey,
            pageToken: nextPageToken,
          },
        });

        if (response.data.items.length === 0) {
          setError('Aucune vidéo trouvée dans la playlist.');
          return;
        }

        setVideos(prevVideos => {
          const newVideos = response.data.items.filter(video => !prevVideos.some(prev => prev.id === video.id));
          return [...prevVideos, ...newVideos];
        });
        setNextPageToken(response.data.nextPageToken || null);
      } catch (error) {
        console.error('Erreur lors de la récupération des vidéos:', error);
        setError('Des vidéos arrivent prochainement');
      }
    };

    fetchVideos();
  }, [playlistId, apiKey, nextPageToken]);

  const sortedVideos = [...videos].sort((a, b) => new Date(a.snippet.publishedAt) - new Date(b.snippet.publishedAt));

  if (error) return <div className="error-message">{error}</div>;

  const currentVideo = sortedVideos[currentIndex];

  const handleCarouselChange = (direction) => {
    const newIndex = currentIndex + direction;
    if (newIndex >= 0 && newIndex < sortedVideos.length) {
      setCurrentIndex(newIndex);
    }
  };

  const handlePreviewClick = (index) => {
    setCurrentIndex(index);
  };

  const previewVideos = sortedVideos.slice(currentIndex + 1, currentIndex + 6);

  return (
    <div className="video-player">
      <h1>{title}</h1>

      {currentVideo ? (
        <div className="video-gallery">
          <div className="video-carousel">
            <button className="carousel-controls left" onClick={() => handleCarouselChange(-1)} disabled={currentIndex === 0}>
              &lt;
            </button>
            <div className="video-item">
              <h3>{currentVideo.snippet.title}</h3>
              <iframe
                width="560"
                height="315"
                src={`https://www.youtube.com/embed/${currentVideo.snippet.resourceId.videoId}`}
                title={currentVideo.snippet.title}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
              <p>{new Date(currentVideo.snippet.publishedAt).toLocaleDateString()}</p>
            </div>
            <button className="carousel-controls right" onClick={() => handleCarouselChange(1)} disabled={currentIndex === sortedVideos.length}>
              &gt;
            </button>
          </div>

          {/* Aperçu des vidéos suivantes */}
          {previewVideos.length > 0 ? (
            <div className="next-videos-preview">
              <h4>Aperçu des vidéos suivantes :</h4>
              <ul>
                {previewVideos.map((video, index) => (
                  <li key={video.id} onClick={() => handlePreviewClick(currentIndex + index + 1)}>
                    <img
                      src={video.snippet.thumbnails.medium.url}
                      alt={video.snippet.title}
                      width="120"
                      height="90"
                    />
                    <p>{video.snippet.title}</p>
                  </li>
                ))}
              </ul>
            </div>
          ) : (
            <div className="end-message">
              <a href="https://www.youtube.com/@Ludic-Adventure" target="_blank" rel="noopener noreferrer">
               Encore plus sur YouTube !
                <img src={Youtube} alt='youtube'/>
              </a>
            </div>
          )}
        </div>
      ) : (
        <p>Aucune vidéo trouvée.</p>
      )}

      {/* Affichage d'une image à la fin de la playlist */}
      {currentIndex === sortedVideos.length && (
        <div className="end-image">
          <a href={endLink} target="_blank" rel="noopener noreferrer">
            <img src={endImage} alt="Fin de la playlist" />
          </a>
        </div>
      )}
    </div>
  );
};

export default VideoPlayer;
