import React, { useState, useEffect } from 'react';
import axios from 'axios';
import NavSecondary from '../../../composants/Nav-contenu';
import Card from '../../../composants/Card';
import { useNavigate } from 'react-router-dom';

export default function DofusAventures() {
  const [articles, setArticles] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}api/articles?tag=Aventurea2`);
        const sortedArticles = response.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        setArticles(sortedArticles);
      } catch (error) {
        console.error('Erreur lors de la récupération des articles', error);
      }
    };
    
    fetchArticles();
  }, []);

  const handleCardClick = (article) => {
    navigate(`/article/${article._id}`, { state: { article } }); // Passer l'article dans l'état
  };

  return (
    <div>
      <NavSecondary currentGame="dofusaventurea2" currentRoute="Aventurea2" /> {/* Passer les paramètres */}
      <div className='actu'>
        <h1>L'aventure à deux</h1>
    
        <ul className='all-card'>
          {articles.map((article) => (
            <Card key={article._id} article={article} onClick={() => handleCardClick(article)} />
          ))}
        </ul>
      </div>
    </div>
  );
}
