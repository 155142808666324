import React from 'react';
import { BrowserRouter as Router, useLocation } from 'react-router-dom'; // Importez useLocation ici
import AppRoutes from './routes';
import './App.scss';
import Footer from './composants/Footer';
import Nav from './composants/Nav';

function Layout() {
  const location = useLocation();  // Récupère l'URL actuelle

  return (
    <div className="App">
      <div className="main-content">
        {location.pathname !== '/' && <Nav />}  {/* Affiche Nav si ce n'est pas '/' */}
        <AppRoutes />
      </div>
      {location.pathname !== '/' && <Footer />} {/* Affiche Footer si ce n'est pas '/' */}
    </div>
  );
}

function App() {
  return (
    <Router>
      <Layout />  {/* Utilisez Layout ici */}
    </Router>
  );
}

export default App;
