// src/pages/Dofus/DofusVideo.js
import React from 'react';
import VideoPlayer from '../../../composants/VideoPlayer';
import NavContenu from '../../../composants/Nav-contenu';

export default function DofusVideo() {
  const API_KEY = 'AIzaSyDmKxhtlFeM5uljkI9wZTYus9SbxqZbGqc'; // Votre clé API
  const PLAYLIST_ID = 'PLPYIAexfSG_9Vp1rH_h-T_eV_nDT_u_Zx'; // ID de votre playlist

  return (
    <>
      <NavContenu currentGame="dofusaventurea2"  />
      <VideoPlayer playlistId={PLAYLIST_ID} apiKey={API_KEY} title="Dernières vidéos de L'aventure à deux" />
    </>
  );
}
