import React from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom'; // Import de useNavigate
import '../style/Layout.scss';

//images 
import Logo from '../images/Nav/Logo.svg'
export default function Layout() {
  const navigate = useNavigate(); // Initialiser useNavigate

  // Fonction pour rediriger vers /actus
  const goToActus = () => {
    console.log('Redirection vers /actus');
    navigate('/actus');
  };

  return (
    <motion.div
    className="layout "
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    transition={{ duration: 1 }}
  >
    <motion.div
      className="logo-container"
      whileHover={{ scale: 1.2, rotate: 10 }}
      transition={{ type: 'spring', stiffness: 300 }}
    >
      <img src={Logo} alt="Logo" className="logo" />
    </motion.div>
    <div className="content-layout ">
      <h1>Bienvenue </h1>
      <p>
        Plongez dans un univers dédié aux aventures vidéoludiques, au partage
        et a la créativité. Préparez-vous a explorer !
      </p>
      <motion.button
        className="cta-button"
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
        onClick={goToActus}
      >
        Découvrez le site
      </motion.button>
    </div>
  </motion.div>
  
  );
}
