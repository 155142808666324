import React, { useState, useEffect } from 'react';
import axios from 'axios';

import '../../../style/admin/TableauDeBord.scss';

export default function TableauDeBord() {
  const [notes, setNotes] = useState([]);
  const [formData, setFormData] = useState({
    titre: '',
    contenu: ''
  });

  // État pour gérer l'ouverture/fermeture des mois
  const [openMonths, setOpenMonths] = useState({});

  useEffect(() => {
    async function fetchNotes() {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}api/notes`);
        setNotes(response.data);
      } catch (error) {
        console.error('Erreur lors du chargement des notes :', error);
      }
    }
    fetchNotes();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.titre || !formData.contenu) {
      alert('Le titre et le contenu sont obligatoires.');
      return;
    }

    const newNote = {
      titre: formData.titre,
      contenu: formData.contenu,
      date: new Date()
    };

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}api/notes`, newNote);
      setNotes((prev) => [response.data, ...prev]); // Ajoute la nouvelle note en haut
      setFormData({ titre: '', contenu: '' });
    } catch (error) {
      console.error("Erreur lors de l'ajout de la note :", error);
    }
  };

  // Fonction pour trier les notes par mois
  const trierParMois = (notes) => {
    const moisNotes = {};

    notes.forEach((note) => {
      const mois = new Date(note.date).toLocaleString('fr-FR', { month: 'long', year: 'numeric' });
      if (!moisNotes[mois]) {
        moisNotes[mois] = [];
      }
      moisNotes[mois].push(note);
    });

    return moisNotes;
  };

  const notesTrieesParMois = trierParMois(notes);

  // Fonction pour gérer l'ouverture/fermeture d'un mois
  const toggleMonth = (mois) => {
    setOpenMonths((prev) => ({
      ...prev,
      [mois]: !prev[mois]
    }));
  };

  return (
    <div className="tableau-de-bord">
      <h2>Tableau de bord Dofus</h2>

      {/* Formulaire pour ajouter une note */}
      <form onSubmit={handleSubmit} className="form-notes">
        <div>
          <label>Titre :</label>
          <input
            type="text"
            name="titre"
            value={formData.titre}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>Contenu :</label>
          <textarea
            name="contenu"
            value={formData.contenu}
            onChange={handleChange}
            required
          />
        </div>
        <button type="submit">Ajouter la note</button>
      </form>

      {/* Affichage des notes triées par mois avec l'effet accordéon */}
        <div className="notes">
          {Object.keys(notesTrieesParMois).map((mois) => (
            <div key={mois} className="mois-notes">
              <h3
                className={`mois-titre ${openMonths[mois] ? 'open' : 'closed'}`}
                onClick={() => toggleMonth(mois)}
              >
                {mois} <span>{openMonths[mois] ? '▼' : '▶'}</span>
              </h3>
              <div className={`notes-list ${openMonths[mois] ? 'open' : 'closed'}`}>
                <ul>
                  {notesTrieesParMois[mois].map((note, index) => (
                    <li key={index} className="note-item">
                      <p><strong>Titre :</strong> {note.titre}</p>
                      <p><strong>Contenu :</strong> {note.contenu}</p>
                      <p><strong>Date :</strong> {new Date(note.date).toLocaleString()}</p>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>

    </div>
  );
}
