import React, { useState, useEffect } from 'react';
// image
import ManofMedan from '../../images/Darkpictures/man_of_medan.webp'
import '../../style/Plusieurpageunmemejeux.scss'


export default function DofusAventures() {
 






  return (
    <div>
  
      <div className="home-page">
        <h1>The Dark Pictures Anthology Aventures</h1>
        <div className='image-dofus'>
          <a href='/dark-pictures/manofmedan'alt='redirect-epopee'><img src={ManofMedan} alt='Dofus3'></img></a>
        </div>
      </div>
    </div>
  );
}
