import React from 'react';

import '../style/Lesite.scss'
export default function Lesite() {
  return (
    <div className="lesite">
      <h1>Pourquoi Ludic-Adventure !</h1>
      <p>
        Ludic-Adventure est bien plus qu'un simple site internet. À l'origine, il a été conçu comme une <strong>archive d'aventures vidéoludiques</strong>, un espace pour préserver et partager nos expériences dans le monde des jeux vidéo. Avec le temps, l'idée evolue est devient un lieu de création et de partage :
      </p>
      <ul>
        <li><strong>Articles passionnants :</strong> Nous rédigeons des articles sur des sujets/jeux qui nous passionnent, allant des présentations de jeux à des guides liées à l'univers vidéoludique.</li>
        <li><strong>Partage de vidéos et d'idées :</strong> Découvrez des vidéos, des concepts, et des réflexions qui nourrissent notre vision et qui, nous l'espérons, enrichiront la vôtre.</li>
      </ul>
      <p>
        Mais Ludic-Adventure ne s'arrête pas là. Ce site est <strong>évolutif</strong> et <strong>adaptatif</strong>, prêt à se transformer pour répondre aux besoins et envies de sa communauté. Nous souhaitons construire ensemble un espace où chacun peut contribuer à ajouter les indispensables qui nous réunissent.
      </p>
      <h2>Comment soutenir Ludic-Adventure ?</h2>
      <p>
        Ce site est développé et maintenu avec soin par <strong>Arf-Dev</strong>. Si vous aimez le contenu que nous proposons et souhaitez participer à son évolution, vous pouvez nous soutenir via notre page. <strong>Je précise tout de même que rien n'est obligatoire, c'est un confort pour nous si vous nous aidez !</strong> <a href="https://fr.tipeee.com/ludic-adventure/" target="_blank" rel="noopener noreferrer"><br></br><br></br>Tipeee</a> Votre aide permet : 
      </p>
      <ul>
        <li>De financer l'hébergement et le développement du site.</li>
        <li>De libérer du temps pour créer encore plus de contenus captivants.</li>
        <li>D'ajouter de nouvelles fonctionnalités plébiscitées par la communauté.</li>
        <li>Ainsi que de nous permettre de profité de notre passion !</li>
        <li>Pour participer aux échanges d'idée pour l'évolution du site je vous laisse vous rendre sur discord dans le channel '-boite-à-idée' :  <a href="https://www.arf-dev.fr" target="_blank" rel="noopener noreferrer">Lien Discord </a></li>
      </ul>
      <p>
        Pour découvrir mes autres projets, rendez-vous sur mon site : <a href="https://discord.gg/drrfzmKY6c" target="_blank" rel="noopener noreferrer">Arf-Dev.fr</a>.
      </p>
      <p>Merci de faire partie de l'aventure !</p>
    </div>
  );
}
