import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import '../style/Nav.scss';
import LogoG from '../images/Nav/LogoG.svg';
import LogoD from '../images/Nav/LogoD.svg';
import Logo from '../images/Nav/Logo.svg';
import PointRouge from '../images/Nav/Pointrouge.svg';
import PointBlanc from '../images/Nav/Pointblanc.svg';
import Line from '../images/Nav/Line.png';
import Ligne from '../images/Nav/Ligne.svg';
import Dev from '../images/Nav/Dev.svg';
import Youtube from '../images/Nav/logo_LA_YT.svg';
// nav admin
import Admin from '../images/Nav/admin.svg';
import Deco from '../images/Nav/Deco.svg';

export default function Nav() {
  const [isAdmin, setIsAdmin] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      const decodedToken = JSON.parse(atob(token.split('.')[1])); // Décodage du token JWT
      if (decodedToken.role === 'admin') {
        setIsAdmin(true);
      }
    }
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('token');
    setIsAdmin(false);
    navigate('/login');
  };

  return (
    <nav>
      <div className="Nav-gauche">
        <div className="nav-desktop">
          <img src={LogoG} alt="LogoG" />
        </div>
        <div className="Logo">
          <NavLink to="/actus">
            <img src={Logo} alt="Logo" />
          </NavLink>
        </div>
        <div className="nav-desktop">
          <a href='https://www.youtube.com/@Ludic-Adventure' alt='redirectYT' target='_blanck'><img src={LogoD} alt="LogoD" /></a>
        </div>
      </div>
      <div className="Nav-mid">
        <div className="nav-item">
          <NavLink to="/actus" className={({ isActive }) => (isActive ? 'active' : '')}>
            <p>ACTUALITÉS</p>
            <div className="points">
              <img src={PointRouge} alt="Point rouge" className="point-rouge" />
              <img src={PointBlanc} alt="Point blanc" className="point-blanc" />
            </div>
          </NavLink>
        </div>
        <div className="ligne">
          <img src={Ligne} alt="trait" />
        </div>
        <div className="line">
          <img src={Line} alt="trait" />
        </div>
        <div className="nav-item">
          <NavLink to="/jeux" className={({ isActive }) => (isActive ? 'active' : '')}>
            <p>Jeux</p>
            <div className="points">
              <img src={PointRouge} alt="Point rouge" className="point-rouge" />
              <img src={PointBlanc} alt="Point blanc" className="point-blanc" />
            </div>
          </NavLink>
        </div>
        <div className="ligne">
          <img src={Ligne} alt="trait" />
        </div>
        <div className="line">
          <img src={Line} alt="trait" />
        </div>
        <div className="nav-item">
          <NavLink to="/streams" className={({ isActive }) => (isActive ? 'active' : '')}>
            <p>STREAMS</p>
            <div className="points">
              <img src={PointRouge} alt="Point rouge" className="point-rouge" />
              <img src={PointBlanc} alt="Point blanc" className="point-blanc" />
            </div>
          </NavLink>
        </div>
        <div className="ligne">
          <img src={Ligne} alt="trait" />
        </div>
        <div className="line">
          <img src={Line} alt="trait" />
        </div>
        <div className="nav-item">
          <NavLink to="/a-propos" className={({ isActive }) => (isActive ? 'active' : '')}>
            <p>A PROPOS</p>
            <div className="points">
              <img src={PointRouge} alt="Point rouge" className="point-rouge" />
              <img src={PointBlanc} alt="Point blanc" className="point-blanc" />
            </div>
          </NavLink>
        </div>
        
      </div>
      <div className="Nav-droite">
        <a href="https://www.hoverthetop.fr/" target="_blank" rel="noreferrer">
          <img src={Dev} alt="dev" className="dev" />
        </a>
        {isAdmin ? (
          <>
            {/* Bouton Admin avec image pour redirection vers /admin-nav */}
            <NavLink to="/admin-nav" className="Admin">
              <img src={Admin} alt="Admin Navigation" />
            </NavLink>
            
            {/* Bouton de déconnexion avec image */}
            <button onClick={handleLogout} className="logout-button">
              <img src={Deco} alt="Déconnexion" />
            </button>
          </>
        ) : (
          // Bouton de connexion
          <a href='https://www.youtube.com/@Ludic-Adventure' alt='redirectYT' target='_blanck'>
            <img src={Youtube} alt="redirectionYT" className="RedirecYT" />
          </a>
        )}
      </div>
    </nav>
  );
}
