// src/pages/ManOfMedan/ManOfMedanVideo.js
import React from 'react';
import VideoPlayer from '../../composants/VideoPlayer';
import NavContenu from '../../composants/Nav-contenu';

export default function ManOfMedanVideo() {
  const API_KEY = 'AIzaSyDmKxhtlFeM5uljkI9wZTYus9SbxqZbGqc'; // Votre clé API
  const PLAYLIST_ID = ''; // ID de la playlist Man of Medan

  return (
    <>
     
      <NavContenu currentGame='worldofwarcraft' />
      <VideoPlayer playlistId={PLAYLIST_ID} apiKey={API_KEY} title="Dernières vidéos de Man Of Medan" />
    </>
  );
}
