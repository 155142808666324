import React from 'react'


// style
import '../style/Footer.scss'
export default function Footer() {
  return (
    <div className='All-footer'>
        <div className='droits'>
        © [2025] [Ludic-Adventure]. Créations originales protégées. Tous droits réservés.
        </div>
        <div className='liens'>
          <a href='/pourquoi-le-site' alt='redirection-lesite'>&gt; Pourquoi le site ? &lt;
          </a>
        </div>
</div>
  )
}
