import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../style/Card.scss';

const Card = ({ article }) => {
  const navigate = useNavigate();

  // Fonction pour rediriger vers le chemin du jeu
  const handleTagClick = (tag) => {
    const gamePaths = {
      DiabloIV: '/diabloIV',
      Satisfactory: '/satisfactory',
      Dofus: '/dofus',
      ManofMedan: '/dark-pictures/manofmedan',
      // Ajoutez d'autres jeux ici en suivant la même structure
    };
    
    if (gamePaths[tag]) {
      navigate(gamePaths[tag]);
    }
  };

  // Fonction pour rediriger vers la page de détails de l'article
  const handleImageClick = () => {
    navigate(`/article/${article.id}`, { state: { article } });
  };

  return (
    <li className="card">
      <h2>{article.title}</h2>

      {article.coverImage && (
        <img
          src={article.coverImage}
          alt={article.title}
          title={article.title}
          onClick={handleImageClick} // Redirection au clic sur l'image
          style={{ cursor: 'pointer' }}
        />
      )}

      <div className="content">
        <div
          className="desc"
          dangerouslySetInnerHTML={{ __html: article.content || "Pas de contenu disponible" }}
        />
        <div className='card-infos'>
          {article.createdBy && <p>Créé par : {article.createdBy}</p>}
          {article.tag && (
            <p
              className='tag'
              onClick={(e) => {
                e.stopPropagation(); // Empêche le clic sur le tag de déclencher le clic sur la carte
                handleTagClick(article.tag);
              }}
              style={{ cursor: 'pointer', color: 'black', textDecoration: 'underline' }}
            >
              {article.tag}
            </p>
          )}
        </div>
      </div>
    </li>
  );
};

export default Card;
