import React, { useState, useEffect } from 'react';
// image
import Dofus3 from '../../images/Dofus/Dofus_E-F_1.webp'
import Dofus2 from '../../images/Dofus/Dofus_A-2_1.webp'
import '../../style/Plusieurpageunmemejeux.scss'

export default function DofusAventures() {
 






  return (
    <div>
     
      <div className="home-page">
        <h1>Dofus Aventure</h1>
        <div className='image-dofus'>
        <a href='/dofus/epopee-fraternelle'alt='redirect-epopee'><img src={Dofus3} alt='Dofus3'></img></a>
          <a href='/dofus/aventure-a-2'alt='redirect-epopee'><img src={Dofus2} alt='Dofus3'></img></a>
         
        </div>
      </div>
    </div>
  );
}
