import React from 'react';
import '../style/Apropos.scss'; // Assurez-vous d'importer le fichier SCSS pour le style
//images
import Discord from '../images/Apropos/Discord.svg'
import Twitter from '../images/Apropos/Thread.svg'
import Twitch  from '../images/Apropos/Logotwitch.png'
import Insta from '../images/Apropos/Logo_insta.webp'
import Youtube from '../images/Apropos/youtube.png'

export default function Apropos() {
  return (
    <div className="apropos-container">
      <h1 className="apropos-title">À Propos de Nous</h1>
      <p className="apropos-text">
        Il y a plus de dix ans, sur les terres colorées de <strong>Dofus</strong>, nous avons eu la chance de ce rencontrer. Ce fut le début d'une belle amitié née de notre passion commune pour les jeux vidéo. Ensemble, nous avons exploré l'univers riche et captivant de Dofus, partageant des heures de jeu à conquérir des donjons, relever des quêtes et affronter des adversaires redoutables. Nos aventures dans ce monde fantastique nous ont permis de tisser des liens forts, construits sur des souvenirs mémorables et des rires partagés.
      </p>
      <p className="apropos-text">
        Au fil des années, notre passion pour le gaming ne s'est pas arrêtée là. Nous avons élargi nos horizons en nous lançant dans de nombreux <strong>MMORPG</strong>, tels que <strong>World of Warcraft</strong>, <strong>Guild Wars 2</strong> ... Chacun de ces jeux a été l'occasion de nouvelles découvertes et d'expériences inoubliables. Nous avons toujours été motivés par le désir d'explorer de nouveaux mondes, de relever des défis ensemble, et de collaborer sur des projets qui nous tiennent à <strong>Coeur</strong>.
      </p>
      <p className="apropos-text">
        En plus de nos aventures en ligne, nous avons également collaboré sur plusieurs projets, mêlant nos compétences respectives. Que ce soit pour développer des guildes en jeu, créer des contenus pour la communauté ou même organiser des événements autour de nos jeux préférés, nous avons toujours cherché à enrichir notre expérience de jeu et celle des autres.
      </p>
      <p className="apropos-text">
        Notre amitié est ancrée dans cette passion commune, et il est incroyable de voir comment notre parcours de joueurs a évolué. Aujourd'hui, nous continuons à jouer à de nouveaux titres, à explorer des univers variés, et à partager notre amour pour le gaming avec notre communauté. Ou va nous mener cette envie de faire ? ................
      </p> 
      <h2 className="apropos-title">Liens Gaming</h2>
      <div className='toutliens'>
           
            <a href='https://discord.gg/drrfzmKY6c' alt='Discord' target='_blanck'><img src={Discord} alt='Discord'></img></a>
            <a href='https://twitch.tv/arfdev' alt='Twitch' target='_blanck'><img src={Twitch} alt='Twitch'></img></a>
            <a href='https://twitch.tv/bijy13' alt='Twitch' target='_blanck'><img src={Twitch} alt='Twitch'></img></a>
            <a href='https://x.com/Bijy1333' alt='Twitter' target='_blanck'><img src={Insta} alt='Twitter'></img></a>
            <a href='https://www.youtube.com/@Ludic-Adventure' alt='Youtube' target='_blanck'><img src={Youtube} alt='Youtube'></img></a>
      </div>
    </div>
  );
}
