import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../../style/admin/AdminNav.scss'; // Assurez-vous de créer le fichier CSS pour styliser

//images

import Article from '../../images/Admin/Ajout_article.webp';
import Screenshot from '../../images/Admin/Gestion_screnshot.webp';
import Article2 from '../../images/Admin/Gestion_article_2.webp';
import Archimonstres from '../../images/Admin/ARCHI.webp';
import Elevage from '../../images/Admin/SPOILER_DD.webp';
import Bankakamas from '../../images/Admin/Bank_kamas.webp'
import Video from '../../images/Admin/Planning_video.webp'
import Liens from '../../images/Admin/Logo_liens_utiles.webp'
import Tableau from '../../images/Admin/Tableau_de_bord.webp'

const adminSections = [
  { title: 'Ajout article, screenshot', path: '/admin-panel', img: Article },
  { title: 'Gestion des articles', path: '/admin-articles', img: Article2  },
  { title: 'Gestion des screenshots', path: '/admin-screenshots', img: Screenshot  },
  { title: 'Archimonstres Dofus', path: '/admin-archimonstre', img: Archimonstres  },
  { title: 'Gestion de l\'élevage', path: '/admin-mount-management', img: Elevage  },
  { title: 'Bank à Kamas', path: '/admin-banquekamas', img: Bankakamas  },
  { title: 'Liens Utiles', path: '/admin-liens', img: Liens  },
  { title: 'Planning vidéo', path: '/admin-planning', img: Video  },
  { title: 'Tableau de bord', path: '/admin-tableau_de_bord', img: Tableau  },
];

export default function AdminNav() {
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <div className="admin-nav">
      <h1>Admin Panel</h1>
      <div className="admin-nav-container">
        {adminSections.map((section) => (
          <div 
            key={section.title} 
            className="admin-card" 
            onClick={() => handleNavigation(section.path)}
          >
            {/* Placeholder for image */}
            <img src={section.img} alt={section.title} className="admin-card-image" />
            <h3>{section.title}</h3>
          </div>
        ))}
      </div>
    </div>
  );
}
